import React from 'react'
import PropTypes from 'prop-types'

import '../../static/styles/capture-form.css'

const CaptureForm = ({ action, inline, buttonText }) => (
  <form
    action={action}
    method="POST"
    className={`${inline ? 'form-inline' : ''} w-100 capture-form`}
  >
    <label className="sr-only" htmlFor="qmFormInputName">
      First Name
    </label>
    <input
      name="opt_in[first_name]"
      required
      type="text"
      className="form-control"
      id="qmFormInputName"
      placeholder="First Name"
    />
    <label className="sr-only" htmlFor="qmFormInputEmail">
      Email
    </label>
    <input
      name="opt_in[email]"
      required
      type="email"
      className="form-control"
      id="qmFormInputEmail"
      placeholder="Email"
    />
    <button type="submit" className="btn btn-primary gray-btn">
      {buttonText}
    </button>
  </form>
)

CaptureForm.propTypes = {
  action: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  inline: PropTypes.bool,
}

CaptureForm.defaultProps = {
  inline: true,
  buttonText: 'Join Now',
}

export default CaptureForm

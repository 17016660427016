import React from 'react'
import Image from "utils/Image"
import RichTextRenderer from 'utils/RichTextRenderer'
import { storyblokEditable } from "gatsby-source-storyblok"

import CourseBanner from './CourseBanner'

import '../../static/styles/blog-post.css'

export default ({ guide, content, contextualBanner, additionalContent }) => {
  const banner = contextualBanner || <CourseBanner />

  return (
    <>
      <div {...storyblokEditable(content)} className="container blog-post-container p-relative">
        <section className="row">
          <div
            className="blog-post order-1 col-12 col-lg-10 col-xl-8"
            style={{
              paddingBottom: '46px',
            }}
          >
            <h1>{guide.name}</h1>
          </div>
          <div className="blog-post order-2 col-12 col-lg-10 col-xl-8">
            {content.hero && (
              <Image img={content.hero} alt={guide.name} className="post-hero" />
            )}
            <div className="post-content">
              <RichTextRenderer data={content.content} />
            </div>
            {additionalContent && (
              <div className="post-content">{additionalContent}</div>
            )}
          </div>
          <div className="side-banners order-4 order-xl-3 col-12 col-lg-10 col-xl-4 p-relative">
            <div className="side-banner sticky-banner">{banner}</div>
          </div>
          <div className="blog-post social-group order-3 order-xl-4 col-12 col-lg-10 col-xl-8">
            <hr />
          </div>
        </section>
      </div>
    </>
  );
}

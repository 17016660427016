import React from 'react'

import '@fontsource/open-sans/700.css'

import '../../static/styles/simple-banner.css'

export default () => (
  <aside id="simple-banner">
    <p className="title">Get QuickMail</p>
    <p className="details">
      See how many more replies you can get with the help of our software.
    </p>
    <hr />
    <a
      title="Get Started"
      href="https://next.quickmail.com/signup"
      className="link"
    >
      Get Started
    </a>
  </aside>
)

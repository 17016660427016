import React, { useState, useEffect } from "react"
import { useStoryblokState } from "gatsby-source-storyblok"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Seo from "components/Seo"

import Guide from '../components/Guide'
import CaptureForm from '../components/CaptureForm'
import SimpleBanner from '../components/SimpleBanner'
import CustomCta from '../components/CustomCta'

const GuidePage = ({ data, path }) => {
  // This will be used to set live editor preview data
  const [previewStory, setPreviewStory] = useState(null);

  // Parse Storyblok data
  const story = useStoryblokState(data.guide);

  // I can't remember why this was needed, so let's first test the Storyblok editor experience with it and then without it
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
      setPreviewStory(null);
    }
  }, [story]);

  // If inside Storyblok editor, execute this
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {

      // Storyblok API endpoint
      const url = `https://api-us.storyblok.com/v2/cdn/stories/${data.guide.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_TOKEN}&cv=${Date.now()}`;

      // Fetch preview data from Storyblok API
      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          setPreviewStory(json.story);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();

      // Let's wait until the Storyblok script has loaded
      const waitForStoryblokToLoad = function () {
        if (!window.StoryblokBridge) {
          setTimeout(waitForStoryblokToLoad, 100)
        } else {
          const { StoryblokBridge } = window
          const storyblokInstance = new StoryblokBridge()

          // Update live preview when Storyblok story changes
          storyblokInstance.on('input', (event) => {
            setPreviewStory(event.story);
          })
        }
      }

      waitForStoryblokToLoad();
    }
  }, []);

  // Conditional that checks whether to show original GraphQL data or the preview data
  const content = previewStory ? previewStory.content : story.content;

  const description = get(
    content,
    'description',
    `${story.name} - Click to read on.`
  )
  const formAction = get(content, 'campaign_optin_url', null)
  const formCopy = get(content, 'campaign_optin_copy', null)
  const defaultAction =
    'https://next.quickmail.com/opt_in/campaign/5ed0cdff9d2f2dffccc4f8ca846484af'

  return (
    <>
      <Seo
        title={`${story.name} | QuickMail`}
        description={description}
        url={data.guide.slug}
      />
      {story && (
        <Guide
          guide={story}
          content={content}
          contextualBanner={<SimpleBanner />}
          additionalContent={
            <div>
              {formCopy ? (
                <div dangerouslySetInnerHTML={{ __html: formCopy }} />
              ) : (
                <div>
                  <h2>Want more resources on cold email like this?</h2>
                  <p>
                    Enter your name and email below and we’ll send you
                    high-quality content every 2 weeks.
                  </p>
                </div>
              )}
              <CustomCta
                isForm="true"
                body="<p><b>Cold email keeps changing every year; what used to work, doesn't anymore.</b></p><p>Join our mailing list and get a free, 8-days course on cold email – starting with the battle-tested CTA Swipe File that we've compiled over the years.</p>"
                buttonText="Get the course"
                formUrl="https://sendfox.com/form/3o2zo1/m292wd"
              />
              {/* <CaptureForm
                  action={
                    formAction && formAction !== '' ? formAction : defaultAction
                  }
                  buttonText="Submit"
                /> */}
            </div>
          }
        />
      )}
    </>
  )
}

export default GuidePage

export const GuidePageQuery = graphql`query GuidePageBySlug($slug: String!) {
  guide: storyblokEntry(slug: {eq: $slug}) {
    name
    content
    slug
  }
}
`
